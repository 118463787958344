.details-checkbox-tooltip {
    text-align: left;
    align-items: center;
}

.details-checkbox-tooltip span{
    height: auto;
}

.tags-title {
    margin-top: 5px;
    margin-bottom: 5px
}

.tags-form-control {
    border: 1px solid #A4AFB7;
    border-radius: 4px;
    width: 30%;
    margin-top: 10px;
}

.tags-form-control:focus-within {
    border: 2px solid black
}

.tags-form-input {
    padding: 16.4px 14px
}

.tags-input-props{
    padding: 0;
}

.webview-input {
    width: 30%;
    margin-top: 10px;
}

.containerDiv {
    display: flex;
    margin-bottom: 30px;
}

.rightChildDiv {
    margin-right: 10%;
    position: relative;
}

.saveButtonGroup {
    display: inline-flex;
    position: absolute;
    bottom: 0;
}

.rightButton {
    margin-left: 20px;
}


.leftChildDiv {
    width: 70%
}

.captchaTxt {
    margin-top: 10px;
    width: 30%;
    margin-bottom: 25px;
}

.interruptedFlowBox {
    margin-bottom: 30px;
}
