.main {
    margin-top: 30px;
}

.text-box {
    width: 20%;
    margin: 20px auto;
}

.assign-promotion-wrap.text-box {
    margin-top: 15px;
}

.row-link {
    font-size: 13px;
    margin-left: 8px;
}

.participation-limits-box {
    width: 450px;
    margin: 0 auto;
}

.date-box {
    width: 40%;
    margin: 0 auto;
}

.input-size {
    width: 320px;
}

.mixcodes-box,
.grid-ratio-winning {
    border: dashed;
    padding: 8px;
    border-color: #a4afb7;
    border-width: 1px;
    border-radius: 15px;
}

.grid-ratio-winning {
    padding-bottom: 12px;
}

.grid-prize-ratios {
    padding-left: 8px;
}

.currency-details,
.prize-ratio-details {
    display: flex;
    justify-content: space-between;
}

.ratio-sum-box {
    width: 100%;
}

.select-currency {
    width: 59%;
}

.grid-prize-ratios>.gridMainContainer {
    margin-top: 10px;
    margin-bottom: 0;
}

.cost-input {
    width: 39%;
}

.functionality-items {
    text-align: left;
    margin-top: 5px;
    margin-bottom: 20px;
}

.limits-box {
    text-align: left;
    padding-left: 10%;
}

.save-promo-icon {
    color: #5fb296;
    font-size: 80px;
    margin-bottom: 40px;
}

.save-promo-buttons {
    margin-left: auto;
    margin-right: auto;
    margin-top: 8px;
    min-width: 300px;
}

.save-promo-box {
    margin-top: 24px;
    margin-bottom: 80px;
}

.form-control {
    height: 40px;
    min-width: 200px;
}

.tooltip-link {
    font-size: 12px;
    color: #f30000;
    text-decoration: none;
}

.participation-limit-tooltip {
    justify-content: left;
    align-items: center
}

.market-language-box {
    margin-top: 16px;
}

.typography-style {
    margin: 12px
}

.assignPromoStepImage {
    margin-bottom: 16px;
}

.backBtn {
    text-transform: none;
    font-weight: 900;
    padding: 2px 15px;
    margin-top: 15px;
}

.backBtn .MuiButton-startIcon {
    margin-right: 0px;
}

.buttonWrap {
    text-align: left;
}

.switch-form {
    width: 20%;
    margin: 0 auto;
}

.currencySpinner {
    margin-bottom: 15px
}

.participationSettingsBox {
    width: 30%;
    margin: 20px auto;
}

.participation-settings-items {
    text-align: left;
    margin-top: 10px;
    margin-bottom: 10px;
}

.participation-settings-rules {
    border: dashed;
    padding: 8px;
    border-color: #a4afb7;
    border-width: 1px;
    border-radius: 15px;
    text-align: left;
}

.validity-checkbox-tooltip {
    text-align: left;
    align-items: center;
}

.validity-checkbox-tooltip span {
    height: auto;
}

.participation-settings-number-spinner {
    height: 40px;
    background-color: white;
}

.text-box-currencies {
    width: 400px;
    margin: 20px auto;
}

.currencies-checkbox {
    margin-left: 5px;
}

.currency-multiselect {
    margin-left: -2px;
    width: 100%;
}

.currency-spinner-input {
    width: 100%;
    background: white;
}

.accumulated-currencies-checkbox {
    margin-left: 5px;
    margin-top: 10px;
}

.currencies-titles {
    position: relative;
    margin-bottom: 50px;
}

.currencies-tooltip-edit-Collect\&\Get {
    position: absolute;
    left: 53%;
    bottom: -3px;
}

.currencies-tooltip {
    position: absolute;
    left: 95%;
    bottom: -3px;
}

.currencies-tooltip-edit-InstantWin {
    position: absolute;
    left: 58%;
    bottom: -3px;
}
